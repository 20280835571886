import { Error404 } from 'app/modules/errors/components/Error404';
import constants from 'commons/constants';
import useStoreSchema from 'commons/useStoreSchema';
import { Redirect, useLocation } from 'react-router-dom';
import reduxConstants from 'redux/reduxConstants';
import getConnectedScreenGroup from 'utils/getConnectedScreenGroup';
import getDefaultComponents from 'utils/getDefaultComponents';
import { MasterLayout } from '_metronic/layout/MasterLayout';
import componentType from './componentTypes';
import InnerViewScreen from './innerViewScreen/InnerViewScreen';
import getComponentSchema from './getComponentSchema';
import MessageHandling from './MessageHandling';
import useValidateResource from 'roles/validateResource';
import logo from '../media/logo/icon.png';

function ScreenComponent({
  match: {
    params: { appID, projectID, screenGroupID, screenID, InnerViewComponentID },
  },
}) {
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const appData = { ...appSchema.app, logo: appSchema.app?.logo || logo };
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const rowID = query.get('__d3__rowID');
  const validateResource = useValidateResource();

  if (InnerViewComponentID) {
    const { component, tableSchema, accessSchema, buttonSchema } = getComponentSchema(
      appSchema,
      InnerViewComponentID
    );

    const { connectedScreenGroup } = getConnectedScreenGroup(
      appSchema,
      InnerViewComponentID,
      component,
      tableSchema,
    );
    // const ComponentType = componentType[component.type || 'table'];
    return (
      <InnerViewScreen
        connectedScreenGroup={connectedScreenGroup}
        routes={{ appID, InnerViewComponentID }}
        component={component}
        tableSchema={tableSchema}
        rowID={rowID}
      />
    );
  }


  const { newProjectID, newScreenGroupID, newScreenID, componentIDs, newScreenName } =
    getDefaultComponents(appData, projectID, screenGroupID, screenID);
    
  if (!screenID && !newScreenID) {
    return (
      <MasterLayout
        appData={appData}
        routes={{
          appID,
          projectID: newProjectID,
          screenGroupID: newScreenGroupID,
          screenID: newScreenID,
          newScreenName,
        }}
      >
        {<Error404 />}
        <MessageHandling />
      </MasterLayout>
    );
  }
  // if there is no component in the screen then the home screen will be called.
  if (!newScreenID) {
    return <Redirect to={`/${appID}/app`} />;
  }
  // resetting the URL according to the default URL.
  if (!screenID) {
    return <Redirect to={`/${appID}/app/${newProjectID}/${newScreenGroupID}/${newScreenID}`} />;
  }
  const isAccess = (componentIDs || [])
    .map((componentID) => validateResource(constants.resourceType.COMPONENT, componentID))
    .some((access) => access !== false);
  return (
    <MasterLayout
      appData={appData}
      routes={{
        appID,
        projectID: newProjectID,
        screenGroupID: newScreenGroupID,
        screenID: newScreenID,
        newScreenName,
      }}
    >
      {isAccess &&
        componentIDs.map((componentObj) => {
          const { component, tableSchema, accessSchema, buttonSchema } = getComponentSchema(
            appSchema,
            componentObj.componentID
          );
          const ComponentType = componentType[component.type || 'table'];
          return (
            <ComponentType
              key={component.componentID}
              component={component}
              tableSchema={tableSchema}
              accessSchema={accessSchema}
              buttonSchema={buttonSchema}
              parentScreen={true}
            />
          );
        })}
      {!isAccess && <Error404 />}
      <MessageHandling />
    </MasterLayout>
  );
}
export default ScreenComponent;
