// form customization API calling component.
import { mls } from 'lib/multilanguagesupport';
import { useState, useEffect } from 'react';
// import { Modal } from 'react-bootstrap-v5';
import { KTSVG } from '_metronic/helpers';
import { ManageCreateData } from 'lib/input-components';
import useOperations from 'screenComponents/useOperations';
import Loading from 'app/modules/errors/components/Loading';
import { useHistory } from 'react-router-dom';

function CreateUpdateWrapper({
  setModalOpen,
  tableConfig,
  component,
  parentScreen = true,
  relationSchema = {},
  handleHideModal,
  rowID = null,
  parentRowID,
}) {
  const [error, setError] = useState({});
  const [formData, setFormData] = useState({});
  // const [updateData, setUpdateData] = useState(0);
  const [columnSchema, setColumnSchema] = useState(tableConfig?.columnSchema || []);
  const isCreate = relationSchema.type === 'hasOne' ? false : !!!rowID;
  const [randVal] = useState(() => Math.random());
  const operations = useOperations(component, rowID, isCreate, relationSchema, parentRowID, randVal);
  // const dataRef = useRef({});
  const createData = operations.createData;
  const formCustomization = operations.formCustomization;
  const history = useHistory();
  console.log(operations.data?.rows?.[0],  formData, 'operations.data?.rows');
  useEffect(() => {
    // formData = operations.data?.rows?.[0] || {};
    // setUpdateData(updateData + 1);
    console.log(operations.data?.rows?.[0], 'internal operations.data?.rows');
    setFormData(operations.data?.rows?.[0] || {});
  }, [operations.data?.rows]);

  const handleFormData = (columnName, value) => {
    const newFormData = { ...formData, [columnName]: value };
    setFormData(newFormData);
  };

  const checkFormCustomization = async () => {
    const formCustomizationResponse =
      tableConfig.isFormCustomization && (await formCustomization(formData));
    if (formCustomizationResponse) {
      formCustomizationResponse?.__d3__result?.__d3__formData &&
        setFormData(formCustomizationResponse.__d3__result.__d3__formData);
      formCustomizationResponse?.__d3__result?.__d3__error &&
        setError(formCustomizationResponse.__d3__result.__d3__error);
      formCustomizationResponse?.__d3__result?.__d3__schema &&
        setColumnSchema(formCustomizationResponse.__d3__result.__d3__schema);
    }
  };

  const handleCreate = async () => {
    if (formData.id) {
      const tempRowID = formData.id;
      delete formData.id;
      operations.updateData(formData, rowID || tempRowID, false);
    } else {
      // creating data and replacing the URL with the new fetched rowID.
      const res = await createData(formData);
      if (res.id && parentScreen) {
        history.replace(`${window.location.pathname}?__d3__rowID=${res.id}`);
      }
    }
    if (relationSchema?.type === 'hasMany') {
      handleHideModal();
    }
  };

  if (operations.isLoading) return <Loading />;
  return (
    <div>
      {/* for one to one header */}
      {!relationSchema.displayName && !handleHideModal && (
        <h2 class='modal-title px-lg-10'>{relationSchema.displayName}</h2>
      )}
      <div className='modal-body py-lg-10 px-lg-10'>
        <div className='d-flex flex-column-fluid'>
          <div className='row gy-5 g-xl-8 col-md-12'>
            <ManageCreateData
              component={component}
              columnSchema={columnSchema}
              // dataRef={dataRef}
              error={error}
              handleFormData={handleFormData}
              formData={formData}
              checkFormCustomization={checkFormCustomization}
            />
          </div>
        </div>

        <div className='flex-row-fluid py-lg-5 p x-lg-15'>
          <div className='d-flex flex-stack pt-3'>
            <div>
              <button type='submit' className='btn btn-lg btn-primary me-3' onClick={handleCreate}>
                <span className='indicator-label'>
                  {(!!rowID || formData.id) ? mls('Update') : mls('Create')}
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2 me-0'
                  />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateUpdateWrapper;
