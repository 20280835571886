import { useQuery } from 'react-query';
import getAppID from 'utils/getAppID';
import { useHistory, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import { mls } from 'lib/multilanguagesupport';
import { DateTime } from 'luxon';
import { supistaAuthApiPost, getURL } from 'lib/server-connection/connections';

const useOperations = (
  component,
  idValue = null,
  isCreate = false,
  relationSchema,
  parentRowID
) => {
  const { componentID, table: tableName, name: componentName } = component;
  const parentScreenData =
    relationSchema?.as && (parentRowID || relationSchema?.type === 'belongsTo')
      ? {
          __d3__relationId: relationSchema?.as,
          __d3__parentId: parentRowID,
          __d3__getAllNotSelected: relationSchema?.getAllNotSelected,
        }
      : {};

  const history = useHistory();
  // const { search } = useLocation();
  // const query = new URLSearchParams(search);
  const dispatch = useDispatch();
  const appID = getAppID(history.location.pathname);
  const [refetchID, setRefetchID] = useState(0);
  const [filterData, setFilterData] = useState(() => {
    const params = {};
    if (idValue && !relationSchema?.type) {
      params['where'] = { id: idValue };
    }
    return params;
  });

  const { isLoading, error, data } = useQuery(
    [tableName, filterData, refetchID, relationSchema],
    () => {
      if (isCreate) return { count: 0, rows: [] };
      try {
        return supistaAuthApiPost(
          `app/${appID}/CRUD/${componentID}`,
          {
            __d3__filterdata: {
              ...filterData,
              where: { ...(filterData.where || {}) },
            },
            ...parentScreenData,
          },
          'GET'
        );
      } catch (error) {
        console.error(error);
        // alert(JSON.stringify(error));
        return { count: 0, rows: [] };
      }
    },
    { keepPreviousData: true }
  );

  const downloadCSV = async () => {
    window.open(
      getURL(
        `app/${appID}/CRUD/${componentID}/downloadCSV/${mls(componentName)}-${encodeURI(
          DateTime.now().toISODate()
        )}.csv`,
        {
          __d3__filterdata: {
            ...filterData,
            where: { ...(filterData.where || {}) },
          },
          ...parentScreenData,
        }
      ),
      '_blank'
    );
    // const csvDataObj = await supistaAuthApiPost;
    // (csvDataObj,`${mls(componentName)}-${new DateTime.now().toLocaleString()}.csv`, 'text/csv')
  };

  const uploadCSV = async (csvData) => {
    let res;
    for (let i = 0; i < csvData.length / 1000; i += 1) {
      const dataToUpload = csvData.slice(i * 1000, (i + 1) * 1000);
      // console.log(dataToUpload, 'dataToUpload');
      res = await supistaAuthApiPost(
        `app/${appID}/CRUD/${componentID}/uploadCSV`,
        { data: { __d3__csvData: dataToUpload, ...parentScreenData } },
        'POST'
      );
    }

    if (res?.bulkCreated > 0) {
      dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.TOAST,
          value: {
            message: `${res.bulkCreated} ${mls('Rows created successfully')}`,
            time: 5,
            isActive: true,
          },
        })
      );
      setTimeout(() => {
        refetch();
      }, 2000);
    } else {
      dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
          value: {
            message: mls('There was a problem while creating row, please try again later!'),
            time: 5,
            isActive: true,
          },
        })
      );
    }
    // download(csvDataObj,`${mls(componentName)}-${new DateTime.now().toLocaleString()}.csv`, 'text/csv')
  };

  const refetch = () => setRefetchID(refetchID + 1);
  const createData = async (createData) => {
    const res = await supistaAuthApiPost(
      `app/${appID}/CRUD/${componentID}`,
      { data: { __d3__newData: createData, ...parentScreenData } },
      'POST'
    );
    refetch();
    if (typeof res.id === 'number' || (typeof res === 'object' && res?.length > 0)) {
      dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.TOAST,
          value: { message: mls('Rows created successfully'), time: 5, isActive: true },
        })
      );
    } else {
      dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
          value: {
            message: mls('There was a problem while creating row, please try again later!'),
            time: 5,
            isActive: true,
          },
        })
      );
    }

    return res;
  };
  const updateData = async (newData, id, isInline = true, where = {}) => {
    const res = await supistaAuthApiPost(
      `app/${appID}/CRUD/${componentID}`,
      {
        data: {
          __d3__newData: newData,
          __d3__id: id,
          ...parentScreenData,
        },
      },
      'PUT'
    );
    if (!isInline) {
      refetch();
      if (typeof res?.[0] === 'number' && res?.[0] > 0) {
        dispatch(
          create({
            setPath: reduxConstants.config.APP_STATUS.TOAST,
            value: { message: mls('Row updated successfully'), time: 5, isActive: true },
          })
        );
      } else {
        dispatch(
          create({
            setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
            value: {
              message: mls('There was a problem while updating row, please try again later!'),
              time: 5,
              isActive: true,
            },
          })
        );
      }
    }
    return res;
  };
  const deleteData = async (id) => {
    try {
      const res = await supistaAuthApiPost(
        `app/${appID}/CRUD/${componentID}`,
        {
          data: {
            __d3__id: id,
            ...parentScreenData,
          },
        },
        'DELETE'
      );
      if (res.deletedObjNo > 0) {
        refetch();
        dispatch(
          create({
            setPath: reduxConstants.config.APP_STATUS.TOAST,
            value: { message: mls('Rows deleted successfully'), time: 5, isActive: true },
          })
        );
      } else {
        dispatch(
          create({
            setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
            value: { message: mls('Row was already deleted.'), time: 5, isActive: true },
          })
        );
      }
      return res;
    } catch (error) {
      dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
          value: {
            message: mls('There was a problem while deleting, please try again later.'),
            time: 5,
            isActive: true,
          },
        })
      );
      return false;
    }
  };

  const uploadFile = async (fileData) => {
    try {
      const formData = new FormData();
      formData.append('__d3__file', fileData); // Append the multimedia file to the FormData
      // Add other necessary data to the FormData if needed
      // Object.entries(parentScreenData).forEach(([key, value]) => {
      //   formData.append(key, value); // Append any parent screen data if needed
      // });
  
      const res = await supistaAuthApiPost(
        `app/${appID}/fileUpload/${componentID}`,
        formData, // Pass the FormData object directly
        'POST',
        { 'Content-Type': 'multipart/form-data' } // Set the Content-Type header for form data
      );
  
      return res; // Return the file URL response on success
    } catch (error) {
      console.error('File upload error:', error);
      return null; // Return null in case of an error
    }
  };
  
  return {
    isLoading,
    error,
    data,
    filterData,
    refetch,
    setFilterData,
    createData,
    updateData,
    deleteData,
    downloadCSV,
    uploadCSV,
    uploadFile,
  };
};

export default useOperations;
