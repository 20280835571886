import ConnectedScreenWrapper from "./ConnectedScreenWrapper";
import { KTSVG } from "_metronic/helpers";
import { useHistory, useLocation } from "react-router-dom";
import CreateUpdateWrapper from "lib/ag-grid-table/CreateModal/CreateUpdateWrapper"
import { mls } from "lib/multilanguagesupport";
import { useState } from "react";
const stepperToSidePanelThreshold = 5;
function InnerViewScreen({
  connectedScreenGroup,
  routes: { appID, InnerViewComponentID },
  component,
  tableSchema: tableConfig,
  rowID,
}) {
  const history = useHistory();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [relatedScreens] = useState(() => {
    const screens = {};
    Object.keys(connectedScreenGroup).forEach((relationID) => {
      if (connectedScreenGroup[relationID]?.relationSchema?.type !== 'belongsTo') {
        screens[relationID] = connectedScreenGroup[relationID];
      }
    });
    return screens;
  });

  const [currentRelatedScreenID] = useState(() => {
    let currentScreenID = query.get('__d3__currentScreenID');
    if (currentScreenID) return currentScreenID;
    // setting default value as 1st screen.
    if (Object.keys(relatedScreens).length > stepperToSidePanelThreshold) return 'default';
    // setting the 1st screen for stepper tabs.
    console.log(relatedScreens, relatedScreens, 'connectedScreenGroupconnectedScreenGroup')
    if (Object.keys(relatedScreens).length > 0) return Object.keys(relatedScreens)[0];
    // there is no related screen to show.
    return '';
  });
  const setQueryParams = (sreenGroupID) => {
    query.set('__d3__currentScreenID', sreenGroupID);
    history.push({
      search: `?${query.toString()}`,
    });
  };


  
  const goMainScreen = () => {
    const path = history.location.pathname.split('/');
    path.pop();
    console.log(path.join('/'), 'history.location.pathname');
    history.push({
      pathname: `${path.join('/')}`,
    });
  };

  return (
    <div className='card'>
      <div className='mx-5'>
        <div className='modal-header py-4'>
          <h2>
            {mls(component?.name || '')}
            {
              rowID
              ? (
                <span className="text-muted">
                { ` | #ID: ${rowID}`}
              </span>
              )
              : ''
            }
            
          </h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={goMainScreen} data-bs-dismiss='modal'>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>
          {
            !currentRelatedScreenID && <CreateUpdateWrapper
            tableConfig={tableConfig}
            component={component}
            isUpdate={true}
            setModalOpen={goMainScreen}
            rowID={rowID}
          />
          }
        {
          currentRelatedScreenID && (
            Object.keys(relatedScreens).length > stepperToSidePanelThreshold 
            ? (
              <div className='row ms-1 my-3' style={{ minHeight: '85vh'}}>
                <div className="col-2 border bg-light">
                  <div className='nav m-2 ms-0 p-1 nav-pills'>
                    <button
                      className={`nav-link my-1 text-start ${currentRelatedScreenID === "default" ? 'active' : ''}`}
                      onClick={() => setQueryParams("default")}
                      style={{ width: '100%' }}
                    >
                      {mls(component?.name)}
                    </button>
                    {Object.keys(connectedScreenGroup).map((relationID, index) => (
                      connectedScreenGroup[relationID]?.relationSchema?.type &&
                      connectedScreenGroup[relationID].relationSchema.type !== 'belongsTo' && (
                        <button
                          key={relationID}
                          className={`nav-link my-1 text-start ${currentRelatedScreenID === connectedScreenGroup[relationID].relationSchema.as ? 'active' : ''}`}
                          onClick={() => setQueryParams(connectedScreenGroup[relationID].relationSchema.as)}
                          style={{ width: '100%' }}
                        >
                          {mls(connectedScreenGroup[relationID].relationSchema.displayName)}
                        </button>
                      )
                    )
                  )}
                </div>
              </div>
              <div className='col-10'>
                {currentRelatedScreenID === "default" ? (
                  <div className="shadow-sm rounded mx-5">
                    <div className="mx-auto pt-2 pb-10">
                      <div className="card mb-5 mb-xl-10">
                        <div className="card-body pt-5 pb-0">
                          <span className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                            {mls(component?.name)}
                          </span>
                          
                        </div>
                        
                        <CreateUpdateWrapper
                          key={`${component.componentID}-${rowID}`}
                          tableConfig={tableConfig}
                          component={component}
                          isUpdate={true}
                          setModalOpen={goMainScreen}
                          rowID={rowID}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <ConnectedScreenWrapper
                    connectedScreenGroup={connectedScreenGroup}
                    componentID={component.componentID}
                    rowID={rowID}
                    currentScreenGroupID={currentRelatedScreenID}
                  />
                )}
              </div>
            </div>
            )
            : (
              <>
              <CreateUpdateWrapper
                key={`${component.componentID}-${rowID}`}
                tableConfig={tableConfig}
                component={component}
                isUpdate={true}
                setModalOpen={goMainScreen}
                rowID={rowID}
              />

              {
                rowID && currentRelatedScreenID &&
                Object.keys(connectedScreenGroup).length > 0
                && (<div
                      className='stepper stepper-links'
                    >
                      <div className='stepper-nav mb-5'>
                        {
                          Object.keys(connectedScreenGroup).map((relationID, index) => (
                            connectedScreenGroup[relationID]?.relationSchema?.type
                            && connectedScreenGroup[relationID].relationSchema.type !== 'belongsTo'
                            && (<div
                              className={`stepper-item ${(!currentRelatedScreenID && index === 0) || currentRelatedScreenID === connectedScreenGroup[relationID].relationSchema.as ? 'current' : ''}`}
                              data-kt-stepper-element='nav'
                              style={{ cursor: 'pointer' }}
                              onClick={() => setQueryParams(connectedScreenGroup[relationID].relationSchema.as)}
                            >
                              <h3 className='stepper-title'>{mls(connectedScreenGroup[relationID].relationSchema.displayName)}</h3>
                            </div>))
                          )
                        }
                      </div>
                      <ConnectedScreenWrapper
                        connectedScreenGroup={connectedScreenGroup}
                        componentID={component.componentID}
                        rowID={rowID}
                        currentScreenGroupID={currentRelatedScreenID}
                      />
                    </div>
                  )
                }
                </>
            )
          )
        }
      </div>
    </div>
  )
  
}
export default InnerViewScreen;
