import getValidatedColumnSchema from "utils/getValidatedColumnSchema";

function getComponentSchema(appSchema, componentID) {
  const component = appSchema.appComponent?.[componentID] || {};
  let tableSchema = appSchema?.appDatatable?.[component.table] || {};
  const buttonSchema = (component.buttons || []).map((buttonID) => appSchema.appButton[buttonID]);
  if (component?.tableList?.length > 0 && component.tableList[0].relationType === 'MAIN') {
    const columnSchema = getValidatedColumnSchema(tableSchema.columnSchema, component.tableList[0].defaultView, component.tableList);
    tableSchema = { ...tableSchema, columnSchema, isFormCustomization: !!component.codeCustomization?.[tableSchema.name] };
  }

  return { component, tableSchema, buttonSchema };
}

export default getComponentSchema;